<template>
  <div
    v-if="!!errorMessage"
    class="absolute text-white bg-red-500 right-0 text-sm rounded-md mt-0.5 py-2 px-3 z-10"
  >
    <p :id="'erro-' + name" v-text="errorMessage"></p>
  </div>
</template>

<script>
export default {
  name: "TailwindInputErrorMessage",
  props: {
    name: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
};
</script>
