<script setup>
import store from '@/store';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const backButtonClickCount = ref(0);

const router = useRouter();
const route = useRoute();

App.addListener('backButton', () => {
    if (store.sidebarOpen) {
        store.sidebarOpen = false;
        return;
    }

    if (route.name !== 'Home' && route.name !== 'Home2') {
        router.go(-1);
        return;
    }

    if (backButtonClickCount.value === 1) {
        backButtonClickCount.value = 0;
        App.exitApp();
    }

    backButtonClickCount.value = 1;

    setTimeout(() => {
        backButtonClickCount.value = 0;
    }, 2000);
});

onMounted(() => {
    if (Capacitor.getPlatform() !== 'web') {
        // Travando app para rodar só no modo retrado
        window.screen.orientation.lock('portrait');
    }
});
</script>

<template>
    <router-view></router-view>
</template>
