import Errors from '@/components/forms/Errors.vue';
import TailwindInput from '@/components/forms/TailwindInput.vue';
import TailwindSubmit from '@/components/forms/TailwindSubmit.vue';
import TailwindTextArea from '@/components/forms/TailwindTextArea.vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { createApp } from 'vue';
import App from './App.vue';
import './index.css';
import './registerServiceWorker';
import router from './router';

createApp(App)
    .use(router)
    .component('TailwindSubmit', TailwindSubmit)
    .component('TailwindInput', TailwindInput)
    .component('TailwindTextArea', TailwindTextArea)
    .component('Errors', Errors)
    .mount('#app');

// Call the element loader after the app has been rendered the first time
// console.log(defineCustomElements);
defineCustomElements(window);
