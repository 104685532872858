import { reactive } from 'vue';

export default reactive({
    notifications: {
        show: false,
        type: '',
        message: '',
    },
    reloadBuilders: false,
    loading: false,
    sidebarOpen: false,
    isOffline: false,
    showOfflineWarning: false,
});
