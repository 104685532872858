import { database } from '@/shared/database';

export const ensureUserIsAuthenticated = async ({ next }) => {
    const authentication = await database.authentication.toCollection().first();

    if (authentication === undefined) {
        // console.log('Sem registros de autenticação - usuário não logado');
        return next({ name: 'Login' });
    }

    // console.log('Possui registros de autenticação - usuário logado');
    return next();
};

export const ensureUserIsGuest = async ({ next }) => {
    const authentication = await database.authentication.toCollection().first();

    if (authentication === undefined) {
        // console.log('Sem registros de autenticação - usuário não logado');
        return next();
    }

    // console.log('Possui registros de autenticação - usuário logado');
    return next({ name: 'Home' });
};
