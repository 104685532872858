<template>
  <button
    :disabled="loading"
    :class="{
      'bg-gray-600 hover:bg-gray-700': !loading,
      'bg-gray-400 hover:bg-gray-400': loading,
    }"
    type="submit"
    class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
  >
    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
      <svg
        v-if="loading"
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        />
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </span>
    <span v-if="loading === false">{{ label }}</span>
    <span v-else>{{ loadingText }}</span>
  </button>
</template>

<script>
export default {
  name: "TailwindSubmit",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Salvar",
    },
    loadingText: {
      type: String,
      default: "Salvando...",
    },
  },
};
</script>
