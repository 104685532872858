<template>
  <label v-if="label" :for="name" v-text="label"></label>

  <div class="mt-1 relative rounded-md shadow-sm">
    <textarea
      :id="name"
      class="appearance-none relative block w-full px-3 py-2 border rounded-md focus:outline-none focus:z-10 sm:text-sm"
      :class="{
        'form-input-invalid': !!errorMessage,
        'form-input':
          meta.valid &&
          meta.dirty === true &&
          modelValue !== null &&
          modelValue !== '',
      }"
      :name="name"
      :rows="rows"
      :value="inputValue"
      v-bind="$attrs"
      :aria-invalid="!!errorMessage ? true : false"
      :aria-describedby="(!!errorMessage ? 'erro-' : '') + name"
      @input="handleModelChange"
      @blur="handleBlur"
    />
    <div
      v-if="!!errorMessage || meta.valid"
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
    >
      <ExclamationCircleIcon
        v-if="!!errorMessage"
        class="h-5 w-5 text-red-500"
        aria-hidden="true"
      />
      <CheckCircleIcon
        v-if="meta.valid"
        class="h-5 w-5 text-green-500"
        aria-hidden="true"
      />
    </div>
  </div>
  <TailwindInputErrorMessage
    :error-message="errorMessage"
    :name="name"
  ></TailwindInputErrorMessage>
</template>

<script>
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/vue/solid";
import TailwindInputErrorMessage from "./TailwindInputErrorMessage.vue";
import { useField } from "vee-validate";
export default {
  name: "TailwindTextArea",
  emits: ["update:modelValue"],
  components: {
    TailwindInputErrorMessage,
    CheckCircleIcon,
    ExclamationCircleIcon,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    rows: {
      type: [String, Number],
      default: 5,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  watch: {
    modelValue: function (currentModelValue) {
      if (!this.inputValue) {
        this.inputValue = currentModelValue;
      }
    },
  },
  methods: {
    triggerInputEvent() {
      const input = document.getElementById(this.name);
      const e = new Event("input");
      input.dispatchEvent(e);
    },
    handleModelChange(event) {
      this.$emit("update:modelValue", event.target.value);
      this.handleChange(event);
    },
  },
};
</script>

<style lang="postcss" scoped>
label {
  @apply block text-sm font-medium text-gray-700;
}
.form-input {
  @apply border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-gray-500 focus:border-gray-500;
}
.form-input-invalid {
  @apply pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500;
}
</style>
