import { createRouter, createWebHistory } from "vue-router";
import { ensureUserIsGuest, ensureUserIsAuthenticated } from "./middlewares";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/Login.vue"),
    meta: {
      middleware: [ensureUserIsGuest],
    },
  },
  {
    path: "/",
    component: () => import("@/components/layouts/Master.vue"),
    meta: {
      middleware: [ensureUserIsAuthenticated],
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/pages/Home.vue"),
      },
      {
        path: "inicio",
        name: "Home2",
        component: () => import("@/pages/Home.vue"),
      },
      {
        path: "obras",
        name: "Constructions",
        component: () => import("@/pages/constructions/Index.vue"),
      },
      {
        path: "visitas",
        name: "Inspections",
        component: () => import("@/pages/inspections/Index.vue"),
      },
      {
        path: "obras/:constructionId/iniciar-visita",
        name: "StartInspection",
        component: () => import("@/pages/inspections/Start.vue"),
        props: true,
      },
      {
        path: "visitas/:inspectionId/quantificacao-residuos",
        name: "InspectionWastes",
        component: () => import("@/pages/inspections/Wastes.vue"),
        props: true,
      },
      {
        path: "visitas/:inspectionId/checklist-de-obra",
        name: "InspectionConstructionChecklist",
        component: () =>
          import("@/pages/inspections/ConstructionChecklist.vue"),
        props: true,
      },
      {
        path: "visitas/:inspectionId/checklist-de-pavimentos",
        name: "InspectionBlocksChecklist",
        component: () => import("@/pages/inspections/BlocksChecklist.vue"),
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
  };

  return middleware[0]({
    ...context,
  });
});

export default router;
