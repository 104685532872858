<script setup>
import { XCircleIcon } from "@heroicons/vue/solid";
import { computed } from "vue";
const props = defineProps({
  errors: {
    type: Object,
    required: true,
  },
});

const errorsMainMessage = computed(() => {
  const ocurrences = Object.keys(props.errors).length;
  if (ocurrences > 1) {
    return `Ocorreram ${ocurrences} erros ao enviar esse formulário.`;
  }
  return "Ocorreu um erro ao enviar esse formulário.";
});
</script>

<template>
  <div
    v-if="Object.keys(props.errors).length > 0"
    class="rounded-md bg-red-50 p-4"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          {{ errorsMainMessage }}
        </h3>
        <div class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc pl-5 space-y-1">
            <li v-for="(error, key) in errors" :key="key">{{ error[0] }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
